
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import HomePage from './components/home';
import ReferralPage from './components/referral';
import RedirectToExternal from './components/RedirectToExternal';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:id" element={<HomePage />} />
        <Route path="/*" element={<HomePage />} />
        <Route path="/referral" element={<ReferralPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
