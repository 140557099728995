import React, { useEffect, useRef, useState } from "react";
import food1 from "../assets/food1.png";
import food2 from "../assets/food2.png";
import Accordion from "react-bootstrap/Accordion";
import { faqData, referralTabs } from "../utils/data.js";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ApiService from "../services/apiService.jsx";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { Spinner } from "react-bootstrap";

const ReferralPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [referlink, setReferlink] = useState(location.state?.referlink);
  const [mobile, setMobile] = useState(window.innerWidth <= 767);
  const [referralActiveKeys, setReferralActiveKeys] = useState([]);
  const [referralListCount, setReferralListCount] = useState({});
  const [friendsOrdersCount, setFriendsOrdersCount] = useState(0);
  const referralId = referlink?.split(`=`)[1];
  const [activeTab, setActiveTab] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [visiblePopover, setVisiblePopover] = useState(null);
  const email = localStorage.getItem("email");

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleEmailLogin = async () => {
    const responseData = await ApiService.referralRegistration(email);
    if (responseData) {
      const referlink = responseData?.Referlink;
      setReferlink(referlink);
    }
  };

  useEffect(() => {
    if (email && !referlink) {
      handleEmailLogin();
    }
  }, [email, referlink]);

  useEffect(() => {
    if (!email && !referlink) {
      navigate("/");
    }
  }, []);

  let popovers;
  if (isMobile) {
    popovers = [
      { id: "twitter" },
      { id: "facebook" },
      { id: "linkedin" },
      { id: "envelope" },
      { id: "send" },
    ];
  } else {
    popovers = [
      { id: "twitter" },
      { id: "facebook" },
      { id: "linkedin" },
      { id: "envelope" },
    ];
  }

  const handleIconClick = (popoverId) => {
    setVisiblePopover(visiblePopover === popoverId ? null : popoverId);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(referlink);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 300);
  };

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 767);
  };
  const fetchReferralListCount = async () => {
    try {
      if (referralId) {
        setIsLoading(true);
        const response = await ApiService.getReferralListCount(referralId);
        if (response) {
          setIsLoading(false);
          setReferralListCount(response.data);
          setFriendsOrdersCount(response?.data?.order);
          determineActiveTab(response.data.count);
        }
      }
    } catch (error) {
      console.error("Error fetching referral list count:", error);
    }
  };
  let isFirstLoaded = false;
  useEffect(() => {
    if (!isFirstLoaded) {
      isFirstLoaded = true;
      fetchReferralListCount();
    }
  }, []);

  const determineActiveTab = (count) => {
    if (count > 25) {
      setActiveTab("fourth");
    } else if (count > 10 && count <= 25) {
      setActiveTab("third");
    } else if (count > 5 && count <= 10) {
      setActiveTab("second");
    } else if (count > 0 && count <= 5) {
      setActiveTab("first");
    } else {
      setActiveTab(null);
    }
  };

  const handleFacebook = (id, e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    setVisiblePopover(visiblePopover === id ? null : id);

    if (id === "facebook") {
      const url = "https://www.perks99.com";
      const text = `Hey, I just joined the Perks99 sustainable workplace
        lunch subscription. If you sign up now with the link
        below, you can get up to 30% off when you subscribe, a
        free lunch, and free delivery for life.`;

      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?&u=${encodeURIComponent(
        url
      )}&ref=${encodeURIComponent(text)}`;
      window.open(facebookUrl, "_blank");
    } else if (id === "twitter") {
      const message = `Hey, I just joined the Perks99 sustainable workplace
        lunch subscription. If you sign up now with the link
        below, you can get up to 30% off when you subscribe, a
        free lunch, and free delivery for life. ${referlink}`;
      const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        message
      )}`;

      window.open(url, "_blank");
    } else if (id === "linkedin") {
      const url = `${referlink}`;
      const title = "Check out Perks99!";
      const summary = `Hey, I just joined the Perks99 sustainable workplace
        lunch subscription. If you sign up now with the link
        below, you can get up to 30% off when you subscribe, a
        free lunch, and free delivery for life. \n ${referlink}`;
      const source = "Perks99";
      const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        url
      )}&title=${encodeURIComponent(title)}&text=${encodeURIComponent(
        summary
      )}&source=${encodeURIComponent(source)}`;

      window.open(linkedinUrl, "_blank");
    } else if (id === "envelope") {
      const email = "";
      const subject = "Join Perks99 and Get Amazing Benefits!";
      const body = `Hey, I just joined the Perks99 sustainable workplace lunch subscription. If you sign up now with the link below, you can get up to 30% off when you subscribe, a free lunch, and free delivery for life. \n ${referlink}`;
      const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.open(mailtoUrl, "_blank");
    } else {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        console.log("User is using a mobile device.");
      } else {
        console.log("User is using a desktop device.");
      }
      const phoneNumber = "";
      const message = `Hey, I just joined the Perks99 sustainable workplace lunch subscription. If you sign up now with the link below, you can get up to 30% off when you subscribe, a free lunch, and free delivery for life. \n ${referlink}`;
      const smsUrl = `sms:${phoneNumber}?&body=${encodeURIComponent(message)}`;
      window.open(smsUrl, "_blank");
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    const handleTabShow = (e) => {
      const chevronTabs = document.querySelectorAll(".chevron");
      chevronTabs.forEach((tab) => {
        tab.classList.remove("active");
        tab.classList.add("inactive");
      });
      e.target.classList.remove("inactive");
      e.target.classList.add("active");
    };

    const tabLinks = document.querySelectorAll('a[data-toggle="tab"]');
    tabLinks.forEach((link) => {
      link.addEventListener("shown.bs.tab", handleTabShow);
    });
    const allKeys = referralTabs.map((item, index) => index.toString());
    setReferralActiveKeys(allKeys);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      tabLinks.forEach((link) => {
        link.removeEventListener("shown.bs.tab", handleTabShow);
      });
    };
  }, []);
  const allKeys = referralTabs.map((item, index) => index.toString());
  return (
    <div className="referral-page">
      <div className="content-referral">
        <header className="header">
          <span>PERKS99</span>
          ,Thank you for signing up.
        </header>
        <section className="invite-section">
          <div className="divied-row">
            <img src={food1} alt="Food" className="food-image-first" />
            <img src={food2} alt="Food" className="food-image-second" />
          </div>

          <div className="divied-row">
            <div className="invite-text">
              <div className="fw-bold fs-6">
                DON’T LEAVE YOUR FRIENDS BEHIND.
              </div>
              <p className="fs-2 fw-bold">
                Invite Friends & Earn Eco-Friendly Packages.
              </p>
              <p>
                Invite friends or co-workers and earn a set of exclusive Perks99
                sustainable food packages. With each milestone in our referral
                program, you'll earn these unique, eco-friendly Perks99 food
                packages.
              </p>
              <div className="share-link">
                <input type="text" value={referlink} />
                <button
                  ref={target}
                  className="copy-button"
                  onClick={handleCopy}
                >
                  <i className="bi bi-copy"></i>
                </button>
                <Overlay target={target.current} show={show} placement="top">
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      Copied!
                    </Tooltip>
                  )}
                </Overlay>
              </div>
              <div className="mt-3">
                {popovers.map((popover) => (
                  <>
                    <a href="#" onClick={(e) => handleFacebook(popover.id, e)}>
                      {popover.id == "twitter" ||
                      popover.id == "facebook" ||
                      popover.id == "linkedin" ? (
                        <i
                          className={`bi bi-${popover.id} social-icon`}
                          onClick={() => handleIconClick(popover.id)}
                        ></i>
                      ) : (
                        <i
                          className={`bi bi-${popover.id}-fill social-icon`}
                          onClick={() => handleIconClick(popover.id)}
                        ></i>
                      )}
                    </a>
                  </>
                  // <OverlayTrigger
                  //   key={popover.id}
                  //   trigger="click"
                  //   placement="bottom"
                  //   show={visiblePopover === popover.id}
                  //   onToggle={() => handleIconClick(popover.id)}
                  //   overlay={
                  //     <Popover id={`popover-${popover.id}`}>
                  //       <Popover.Body>
                  //         Hey, I just joined the Perks99 sustainable workplace
                  //         lunch subscription. If you sign up now with the link
                  //         below, you can get up to 30% off when you subscribe, a
                  //         free lunch, and free delivery for life.{" "}
                  //       </Popover.Body>
                  //     </Popover>
                  //   }
                  //   ref={(ref) => (popoverRefs.current[popover.id] = ref)}
                  // >
                  // </OverlayTrigger>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="friends-faq">
        <section className="friends-join">
          <div className="main-h2 d-flex margin-bottom">
            <h2 className="first-contain">Friends</h2>
            <h2 className="friends-title">Join</h2>
          </div>
          <Tab.Container id="left-tabs-example" activeKey={activeTab}>
            <div className="row main-div-tab">
              <div className="col-sm-12 w-100">
                <Nav variant="pills">
                  <Nav.Item className="nav-item-po">
                    <Nav.Link eventKey="first" className="chevron ">
                      {" "}
                      5 FRIENDS JOIN
                    </Nav.Link>
                    <div
                      className={classNames(
                        {
                          "content-below left-position": !mobile,
                          "referral-content": mobile,
                        },
                        "active "
                      )}
                    >
                      <Tab.Content>
                        <p className="divider">|</p>
                        <p className="content-p">
                          {" "}
                          Free reusable bamboo cutlery and complimentary
                          breakfast for you and your 5 friends.
                        </p>
                      </Tab.Content>
                    </div>
                  </Nav.Item>
                  <Nav.Item className="nav-item-po">
                    <Nav.Link eventKey="second" className="chevron ">
                      {" "}
                      10 FRIENDS JOIN
                    </Nav.Link>
                    <div
                      className={classNames(
                        {
                          "content-above": !mobile,
                          "referral-content": mobile,
                        },
                        "active "
                      )}
                    >
                      <Tab.Content>
                        {mobile && <p className="divider">|</p>}
                        <p className="content-p">
                          {" "}
                          Free bamboo cutlery, delivery bag, and complimentary
                          lunch for you and your 10 friends.
                        </p>
                        {!mobile && <p className="divider">|</p>}
                      </Tab.Content>
                    </div>
                  </Nav.Item>
                  <Nav.Item className="nav-item-po">
                    <Nav.Link eventKey="third" className="chevron ">
                      {" "}
                      25 FRIENDS JOIN
                    </Nav.Link>
                    <div
                      className={classNames(
                        {
                          "content-below": !mobile,
                          "referral-content": mobile,
                        },
                        "inactive"
                      )}
                    >
                      <Tab.Content>
                        <p className="divider">|</p>
                        <p className="content-p">
                          Free bamboo cutlery, a delivery bag, a plate, and
                          complimentary lunch for you and your 25 friends.
                        </p>
                      </Tab.Content>
                    </div>
                  </Nav.Item>
                  <Nav.Item className="nav-item-po">
                    <Nav.Link eventKey="fourth" className="chevron ">
                      {" "}
                      49 FRIENDS JOIN
                    </Nav.Link>
                    <div
                      className={classNames(
                        {
                          "content-above": !mobile,
                          "referral-content": mobile,
                        },
                        "inactive"
                      )}
                    >
                      <Tab.Content>
                        {mobile && <p className="divider">|</p>}
                        <p className="content-p">
                          Free set of sustainable packages, complimentary
                          breakfast, and lunch for you and your 49 friends
                        </p>
                        {!mobile && <p className="divider">|</p>}
                      </Tab.Content>
                    </div>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          </Tab.Container>
        </section>
        <section className="section_works">
          <h5>
            <strong>How It Works:</strong>{" "}
          </h5>
          <ul style={{ marginTop: "15px" }}>
            <li>
              <strong>Sign Up:</strong> Join the Perks99 prelaunch campaign.
            </li>
            <li>
              <strong>Refer Friends:</strong> Share your unique referral link
              with friends via email or social media.
            </li>
            <li>
              <strong>Earn Rewards:</strong> Track your progress and earn
              sustainable rewards as friends join.
            </li>
          </ul>
        </section>
        <div className="friends-orders-container">
          <div className="d-flex justify-content-center g-5">
            <h2 className="friends-orders-title me-2">FRIENDS</h2>
            <h2 className="friends-title">ORDERS</h2>
          </div>
          <p className="friends-orders-description">
            Your friends' orders will count toward your 49-order milestone,
            which means you'll earn the free Perks99 bamboo food packages after
            a total of 49 orders have been placed by you and your referrals.
          </p>
          <div className="friends-orders-counters">
            <div className="counter">
              <div className="counter-value">
                {referralListCount?.count ? referralListCount?.count : 0}
              </div>
              <div className="counter-label">Friends Have Joined</div>
            </div>
            <div className="counter">
              <div className="counter-value">{friendsOrdersCount}</div>
              <div className="counter-label">Friends Have Ordered</div>
            </div>
          </div>
          <button
            className="keep-checking-button"
            onClick={fetchReferralListCount}
          >
            {isLoading ? (
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "KEEP CHECKING"
            )}
          </button>
        </div>
        <section className="faq">
          <div className="d-flex justify-content-center gap-2">
            <h2 className="friends-orders-title">Referrals</h2>
            <h2 className="friends-title">FAQ</h2>
          </div>
          <div className="faq-item">
            <Accordion defaultActiveKey="0">
              {faqData.map((item, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{item.title}</Accordion.Header>
                  <Accordion.Body>{item.content}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReferralPage;
