import React, { useEffect, useState } from "react";
import logo from "../assets/homeBackgroundBanner.jpeg";
import Rectangle from "../assets/Rectangle 336.png";
import HomeSavour from "../assets/homesavour.png";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ApiService from "../services/apiService";
import { Spinner } from "react-bootstrap";

const HomePage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleStepInside = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.", {
        position: "top-right",
        theme: "colored",
      });
      return;
    }
    setIsLoading(true);
    const localEmail = localStorage.getItem("email");
    if (!localEmail || localEmail !== email) {
      localStorage.setItem("email", email);
    }
    const responseData = await ApiService.referralRegistration(email);
    if (responseData.message === "Email already exists") {
      const referlink = responseData?.Referlink;
      setIsLoading(false);
      navigate("/referral", { state: { referlink } });
    } else {
      const referlink = responseData?.Referlink;
      setIsLoading(false);
      navigate("/referral", { state: { referlink } });
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.backgroundColor = "#F2EFEE";
    }
  }, []);

  return (
    <div className="homepage">
      <div className="content">
        <div className="image-container">
          <div className="image_banner"></div>
          {/* <img src={logo} alt="Clock" className="clock-image" /> */}
          {/* <img src={Rectangle} alt="Clock" className="clock-img" /> */}
        </div>
        <div className="savor">
          <span className="savor_title">
            Savor Convenience. Embrace Sustainability.
          </span>
          <span className="savor_subtitle">
            Your Eco-Friendly Workplace Lunch Subscription Service Reimagined.
          </span>
        </div>
        <section className="savor_section">
          <div className="section_division">
            <span className="note_perks">
              At Perks99, we revolutionize workplace lunches with eco-friendly
              meal subscriptions. Partnering with 600+ restaurants, we deliver
              fresh, warm meals in reusable bamboo packages via
              temperature-controlled pickup pods, eliminating single-use plastic
              waste.
            </span>
          </div>
          <div className="section_division section_division_end">
            <img src={HomeSavour} />
          </div>
        </section>
        <div className="offers">
          <div className="main-h2">
            <h2 className="first-contain">Exclusive Pre-launch Offers For</h2>
            <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
              The First 99 Subscribers
            </h2>
          </div>
          <div className="offer-cards">
            <div className="offer-card">
              Up to 30% off and free delivery for life.
            </div>
            <div className="offer-card">
              Free monthly gym membership with 30 meals/monthly plan.
            </div>
            <div className="offer-card">
              Free set of eco-friendly food packages after 49 orders.
              <div style={{ color: "#6AA84F" }}>($190 value)</div>
            </div>
          </div>
        </div>
        <div className="offer_join">
          Join Perks99 today and enjoy a convenient, stress-free, sustainable
          lunch solution crafted just for you!
        </div>
        <div className="email-subscription">
          <div className="main-h2">
            <h2 className="email_entered">Have you entered?</h2>
            {/* <h2></h2> */}
          </div>
          <div className="email-input">
            <input
              type="email"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleStepInside}>
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "STEP INSIDE"
              )}
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
