export const faqData = [
    {
      "title": "What is the Perks99 Referral Program? ",
      "content":  "The Perks99 Referral Program is an exclusive opportunity! You can receive personal, elegant lunch boxes, cutlery, and a retro delivery bag, all for free. By referring friends, family, and colleagues to Perks99, you can enjoy a free lunch and sustainable packaging. It’s our way of showing appreciation for your role in expanding the Perks99 community!"
    },
    {
      "title": "How does the Perks99 referral program work?",
      "content": "Share your link with your audience through email or social media. They sign up for Perks99 and subscribe to the meal plans using your referral link."
    },
     {
        "title": "How many friends can I invite?",
        "content": "There’s no limit on the number of friends or coworkers you can refer to Perks99! That means unlimited earnings, you all!"
      },
      {
        "title": "How do I join the Perks99 Referral Program?",
        "content": "To join the Perks99 referral program, sign up at www.perks99.com. After signing up, you’ll see your unique referral link, which you can copy and share."
      },
       {
        "title": "Will this apply to every referral I get?",
        "content": "Yes! Every user who signs up to Perks99 using your tracking link is considered your referral.You start earning when they sign up and become a paying subscriber!"
      },
      {
        "title": "What happens when a user you refer signs up?",
        "content": "You'll receive an email as soon as they sign up, and when they become paying subscribers, you start earning!"
      } ,
      {
        "title": "Do I need to be an existing user to join the Perks99 Referral Program?",
        "content": "No. You don’t have to be a Perks99 user to join the program. Just sign up and get your unique referral link."
      },
      {
        "title": "How do I refer a friend?",
        "content": "Copy your unique referral link, a personalized URL that tracks the people you refer to our service, and share it through email or Social Media."
      },
       {
        "title": "Who can I refer to earn rewards?",
        "content": "Anyone"
      },
      {
        "title": "To earn rewards, what conditions must met?",
        "content": "Your referrals' signs-up and orders, it’ll earn you Perks99 sustainable food packages."
      },
       {
        "title": "What counts as a successful referral?",
        "content": "Whenever someone signs up and subscribes to one of our meal plans through your referral link"
      },
      {
        "title": "How do I track my referral status?",
        "content": "You can easily track how many friends sign up and order by entering your email here:www.perks99.com"
      },
       {
        "title": "How can I tell if a friend has used my link?",
        "content": "You’ll receive an email stating that your friend has signed up or ordered a meal using your referral code. The reward will be sent to your dashboard within a few business days."
      },
      {
        "title": " How will I receive my reward?",
        "content": "When you earn a reward, such as a free meal or a food packaging product, you'll receive a notification via Email or SMS. The free meal coupons will be sent to you electronically, and the food packaging products will be delivered to your workplace or home address."
      } ,
      {
        "title": "Do referral rewards expire?",
        "content": "No, the rewards are available for redemption whenever you choose"
      },
      {
        "title": "Where can I find my referral code/referral link?",
        "content": "After signing up, your unique referral link will automatically be generated and emailed to you.You can also access it by entering your email here: www.perks99.com"
      } ,
      {
        "title": "Where can I reach out if my question wasn’t answered here?",
        "content": "Reach out to us at hello@perks99.com with all your queries."
      }
  ]

export const referralTabs = [
  {
    "title": "5 Friends can join",
    "content": "Get a free set of reusable cutlery."
  },
  {
    "title": "10 FRIENDS JOIN",
    "content": " Get a free set of reusable cutlery and a delivery bag."
  },
  {
    "title": "25 FRIENDS JOIN",
    "content": "Get a free set of reusable cutlery, a delivery bag, and a plate."
  },
  {
    "title": "49 FRIENDS JOIN",
    "content": "Receive a free set of Perks99 sustainable packages and a complimentary lunch for you and your 49 friends"
  }
]
  
