import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: "https://api.perks99.com/api/customer", // Replace with your API base URL
});
const ApiService = {
  referralRegistration: async (email) => {
    try {
      const urlString = window.location.href;
      const parsedUrl = new URL(urlString);
      const referralId = parsedUrl.searchParams.get("ref");

      const postData = { email };

      if (referralId) {
        postData.referral = referralId;
      }

      const response = await api.post("/referralRegistration", postData);

      if (response.data.status) {
        return response.data.data;
      }
    } catch (error) {
      toast.error("Error registering referral. Please try again.");
    }
    return null;
  },

  getReferralListCount: async (referralId) => {
    try {
      const response = await api.get(
        `/referralListCount?referralId=${referralId}`
      );
      return response.data;
    } catch (error) {
      toast.error("Error fetching referral list count. Please try again.");
      console.error("Error fetching referral list count:", error);
      throw error;
    }
  },
};

export default ApiService;
